
























































































































































































































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import BookAppointmentNew from "@/doctor-app/components/BookAppointmentNew.vue";
import BookAppointmentOld from "@/doctor-app/components/BookAppointmentOld.vue";

@Component({
  components: {
    BookAppointmentNew,
    BookAppointmentOld,
  },
})
export default class Calendar extends Vue {
  myDate: any = null;
  highlighted: any = {};
  public currentDate: Date = new Date();
  public showAppoitmentNew: boolean = false;
  public showAppoitmentOld: boolean = false;
  public chamberId: any = 0;
  public scheduleId: any = 0;
  public WeeklyDate: any = [];
  public weekDay: any = [];
  public currentDay: Date;

  formatter(date: any) {
    var last = new Date(date.getTime());
    var day = last.getDate() - 1;
    var month = last.getMonth() + 1;
    var year = last.getFullYear();
    var fulld = "";
    if (day < 10) {
      fulld = Number(year) + "-" + Number(month) + "-0" + Number(day);
    } else if (month < 10) {
      fulld = Number(year) + "-0" + Number(month) + "-" + Number(day);
    } else if (month < 10 && day < 10) {
      fulld = Number(year) + "-0" + Number(month) + "-0" + Number(day);
    } else {
      fulld = Number(year) + "-" + Number(month) + "-" + Number(day);
    }

    return fulld;
  }
  findSchedule(value: any) {
    if (value == 1) {
      var increment = new Date(this.currentDate.getTime() + 1 * 24 * 60 * 60 * 1000);
      this.currentDate = increment;
    } else if (value == -1) {
      var decrement = new Date(this.currentDate.getTime() - 1 * 24 * 60 * 60 * 1000);
      this.currentDate = decrement;
    } else if (value == 7) {
      decrement = new Date(this.currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
      this.currentDate = decrement;
    }
    this.changedTimeFrom(this.currentDate);

    //alert(this.currentDate);
  }

  closeModal(value: any) {
    this.showAppoitmentNew = value;
  }
  closeOldBA(value: any) {
    this.showAppoitmentOld = value;
  }

  getScheduleId(value: any, value2: any) {
    this.scheduleId = value;
    this.currentDay = value2;
  }

  changedTimeFrom(value: any) {
    this.WeeklyDate = [];
    this.weekDay = [];
    var days = 7;
    for (var i = 0; i < days; i++) {
      if (i == 0) {
        i = 1;
        days += 1;
      }
      var date = value;
      var last = new Date(date.getTime() + i * 24 * 60 * 60 * 1000);

      var day = last.getDate() - 1;
      var month = last.getMonth() + 1;
      var year = last.getFullYear();

      if (day < 10) {
        const fulld = Number(year) + "-" + Number(month) + "-0" + Number(day);
        this.WeeklyDate.push(fulld);
      } else if (month < 10) {
        const fulld = Number(year) + "-0" + Number(month) + "-" + Number(day);
        this.WeeklyDate.push(fulld);
      } else if (month < 10 && day < 10) {
        const fulld = Number(year) + "-0" + Number(month) + "-0" + Number(day);
        this.WeeklyDate.push(fulld);
      } else {
        const fulld = Number(year) + "-" + Number(month) + "-" + Number(day);
        this.WeeklyDate.push(fulld);
      }
      var weekdays = ["SAT", "SUN", "MON", "TUE", "WED", "THU", "FRI"];
      var day2 = weekdays[last.getDay()];
      this.weekDay.push(day2);
    }
    // console.log(this.WeeklyDate);
    // console.log(this.weekDay);
  }

  timeFrom() {
    var days = 7;
    for (var i = 0; i < days; i++) {
      if (i == 0) {
        i = 1;
        days += 1;
      }
      var date = new Date();
      var last = new Date(date.getTime() + i * 24 * 60 * 60 * 1000);

      var day = last.getDate() - 1;
      var month = last.getMonth() + 1;
      var year = last.getFullYear();

      if (day < 10) {
        const fulld = Number(year) + "-" + Number(month) + "-0" + Number(day);
        this.WeeklyDate.push(fulld);
      } else if (month < 10) {
        const fulld = Number(year) + "-0" + Number(month) + "-" + Number(day);
        this.WeeklyDate.push(fulld);
      } else if (month < 10 && day < 10) {
        const fulld = Number(year) + "-0" + Number(month) + "-0" + Number(day);
        this.WeeklyDate.push(fulld);
      } else {
        const fulld = Number(year) + "-" + Number(month) + "-" + Number(day);
        this.WeeklyDate.push(fulld);
      }

      var weekdays = ["SAT", "SUN", "MON", "TUE", "WED", "THU", "FRI"];
      var day2 = weekdays[last.getDay()];
      // alert(last.getDay());
      this.weekDay.push(day2);
      //alert(day2);
    }
    // console.log(this.weekDay);
    // console.log(this.WeeklyDate);
  }

  formatDate(value: any) {
    var fulld = "";
    var last = new Date(value);
    var day = last.getDate();
    var month = last.getMonth() + 1;
    var year = last.getFullYear();

    if (day < 10) {
      fulld = Number(year) + "-" + Number(month) + "-0" + Number(day);
    } else if (month < 10) {
      fulld = Number(year) + "-0" + Number(month) + "-" + Number(day);
      this.WeeklyDate.push(fulld);
    } else if (month < 10 && day < 10) {
      fulld = Number(year) + "-0" + Number(month) + "-0" + Number(day);
      this.WeeklyDate.push(fulld);
    } else {
      fulld = Number(year) + "-" + Number(month) + "-" + Number(day);
      this.WeeklyDate.push(fulld);
    }
    // console.log(fulld);
    return fulld;
  }
}
